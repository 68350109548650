import styled from 'styled-components';
import { Input } from '~/components';

const { Search } = Input;

export const Row = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const SearchTool = styled(Search)`
  margin-bottom: 8px;
  width: 50%;
`;
