import React, { createRef, useEffect, useState } from 'react';
import {
  InformacaoFilter,
  PageTitle,
  Card,
  ButtonGoBack,
  Exportacao,
} from '~/components';
import { notification } from 'antd';
import { getUrlComDominio } from '~/modules/Helper/getUrl-helper';
import { fileManagerPaiDownload } from '~/services/FileManager/file-manager.service';
import * as S from './styles';
import TreeViewModuloFixo from '../components/treeViewModuloFixo.component';
import {
  getModuloFixoService,
  exportarVisao,
} from '../services/moduloFixo.service';

function ModuloFixo({ match, history }) {
  const [informacao, setInformacao] = useState({ __html: null });
  const [treeView, setTreeView] = useState([]);
  const [titulo, setTitulo] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const treeViewMethods = createRef({});
  const infoMethods = createRef({});

  const { modulo, visao } = match.params;

  useEffect(() => {
    async function getModuloFixo() {
      const response = await getModuloFixoService(modulo, visao);
      if (response && response.data) {
        setInformacao(response.data.Informacao);
        setTreeView(response.data.VisaoItens);
        setTitulo(response.data.Titulo);
      }
    }

    getModuloFixo();
  }, [modulo, visao]);

  function goBack() {
    history.push(`/`);
  }

  function onSearch(busca) {
    if (informacao?.Texto) infoMethods.current.filterElements(busca);
    if (treeView?.length > 0) treeViewMethods.current.onSearch(busca);
  }

  function onChange(e) {
    if (informacao?.Texto) infoMethods.current.onChangeInfo(e);
    if (treeView?.length > 0) treeViewMethods.current.onChange(e);
  }

  function exportar(tipoExportacao) {
    if (tipoExportacao === 'uri') {
      navigator.clipboard.writeText(window.location.href);
      notification.success({
        message: 'URI Copiada com Sucesso!',
      });
    } else {
      const dtoExportacao = {
        ChaveModulo: modulo,
        NomeVisao: visao,
        UrlExportacao: getUrlComDominio(),
      };

      exportarVisao(tipoExportacao, dtoExportacao).then(res => {
        fileManagerPaiDownload(res.data);
      });
    }
  }

  return (
    <>
      <PageTitle titulo={titulo} />
      <S.SearchTool
        placeholder={`Filtrar ${titulo}`}
        onSearch={busca => onSearch(busca)}
        onChange={e => onChange(e)}
        allowClear
        value={searchValue}
      />
      {informacao?.Texto && (
        <>
          <InformacaoFilter
            ref={infoMethods}
            titulo={informacao.Titulo}
            informacao={informacao.Texto}
            setSearchValue={setSearchValue}
          />
        </>
      )}
      {treeView?.length > 0 && (
        <>
          <Card>
            <TreeViewModuloFixo
              ref={treeViewMethods}
              setSearchValue={setSearchValue}
              treeView={treeView}
              history={history}
              modulo={modulo}
              visao={visao}
            />
          </Card>
        </>
      )}
      <S.Row>
        <ButtonGoBack OnClick={goBack} />
        <Exportacao onClick={exportar} moduloFixo />
      </S.Row>
    </>
  );
}

export default ModuloFixo;
