import api from '~/services/api';

function getModuloFixoService(modulo, visao) {
  return api.get(`modulovisao/fixo/${modulo}/${visao}`);
}

async function exportarVisao(tipoExportacao, data) {
  return api.post(`modulovisao/fixo/${tipoExportacao}`, data);
}

export { getModuloFixoService, exportarVisao };
